import React, {Component} from "react";

class Footer extends Component {
    render() {
        return (
            <footer id="footerComponent">
                <p>©2024 - matt pero </p>
            </footer>
        )
    }
}

export default Footer;